import { QuebicMessageReaction } from "./reaction";
import { QuebicUser } from "./user";

/**
 * The possible values for a message's reference type.
 *
 * @export
 * @enum {number}
 */
export enum QuebicMessageReferenceType {
	Reply = "reply",
}

/**
 * The required information to create a new message.
 *
 * @export
 * @interface QuebicMessageCreate
 */
export interface QuebicMessageCreate {
	/**
	 * The message content or (body) that the user sees. Supports markdown format.
	 *
	 * @type {string}
	 * @memberof QuebicMessageCreate
	 */
	content: string,
	/**
	 * A message id to reference when this message is a reply.
	 *
	 * @type {string}
	 * @memberof QuebicMessageCreate
	 */
	replying_to?: string,
	/**
	 * A list of embeds to link to this message.
	 */
	embeds?: QuebicMessageEmbedCreate[],
}

/**
 * The updatable attributes of a message.
 *
 * @export
 * @interface QuebicMessageUpdate
 */
export interface QuebicMessageUpdate {
	content: string,
	attachments?: QuebicMessageAttachment[],
	embeds?: QuebicMessageEmbedUpdate[],
}

/**
 * The type of message embed.
 */
export enum QuebicMessageEmbedType {
	/**
	 * A custom embed, this data is never rendered by official Quebic implementations.
	 */
	Custom = "custom",
	/**
	 * An attached file for download.
	 */
	File = "file",
	/**
	 * An attached link with title, description and optional icon.
	 */
	Link = "link"
}

/**
 * Represents an embedded file, link, or custom data on a message.
 */
export interface QuebicMessageEmbed {
	/**
	 * Unique id for this embed.
	 */
	id: string;
	/**
	 * The type of embed.
	 */
	type: QuebicMessageEmbedType;
	/**
	 * Optional icon for display on a `File` or `Link`.
	 */
	icon?: string;
	/**
	 * Url of the `File` or `Link` of this embed.
	 */
	url?: string;
	/**
	 * Description of the embed if the type is `Link`.
	 */
	description?: string;
	/**
	 * - File name of the embed if the type is `File`.
	 * - Title of the embed if the type is `Link`.
	 */
	file_name?: string;
	/**
	 * Size in bytes of the embed if the type is `File`.
	 */
	size?: number;
}

/**
 * Specific embed values required to update one.
 */
export type QuebicMessageEmbedUpdate = Omit<QuebicMessageEmbed, "type">;
/**
 * Specific embed values required to create one.
 */
export type QuebicMessageEmbedCreate = Omit<QuebicMessageEmbed, "id">;

/**
 * Represents a file attached to a message.
 */
export interface QuebicMessageAttachment {
	/**
	 * Unique id for this attachment.
	 */
	id: string,
	/**
	 * The file name of the attachment.
	 */
	file_name?: string,
	/**
	 * The mime type of the attachment.
	 */
	content_type?: string,
	/**
	 * If the attachment is an image, this is the width of the image.
	 */
	width?: number,
	/**
	 * If the attachment is an image, this is the height of the image.
	 */
	height?: number,
	/**
	 * Size in bytes of the attachment.
	 */
	size?: number,
}

/**
 * Represents a text/rich content message.
 *
 * @export
 * @interface QuebicMessage
 */
export interface QuebicMessage {
	id: string,
	owner?: QuebicUser,
	channel_id?: string,
	content?: string,
	pinned?: boolean,
	reactions?: QuebicMessageReaction[],
	attachments?: QuebicMessageAttachment[],
	embeds?: QuebicMessageEmbed[],
	reference_type?: QuebicMessageReferenceType,
	reference_message?: QuebicMessage,
	timestamp?: Date,
	edit_timestamp?: Date
}

/**
 * Represents a message that was 'read' in a channel.
 */
export interface QuebicMessageAcknowledge {
	id: string;
	channel_id: string;
}