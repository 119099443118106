/*
		----------------------------------------------------------------
		This file is internal use, not documented on purpose
		Please use the exposed library classes to interface with Quebic
		----------------------------------------------------------------
*/

import { QuebicWsErrorCodes } from "../../base";
import { EventEmitter } from "events";
import type { Lz4API } from "lz4-wasm";

// Support loading the browerified module when we're building webpack, otherwise use nodejs for debugging.
let lz4Module: Promise<{ default: Promise<Lz4API> }> | null = null;
let lz4: Lz4API | null = null;

if (process.env.IS_WEBPACK === "webpack") {
	lz4Module = import("lz4-wasm") as any;
} else {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	lz4 = require("lz4-wasm-nodejs") as any;
}

import WebSocket, { CloseEvent, MessageEvent } from "isomorphic-ws";
import { Message } from "../message/message";
import { Heartbeat } from "../message/heartbeat";

export interface GatewayMessage<T = any> {
	o: number;
	d: T;
}

export enum GatewayManagerEvents {
	Connected = "connected",
	Connecting = "connecting",
	Disconnected = "disconnected",
	Error = "error",
	Message = "message",
}

export class GatewayManager extends EventEmitter {
	private url: string;
	private ws: WebSocket | null;
	private heartbeat: number;
	private reconnecting: boolean;
	private decoder: TextDecoder;
	private open: boolean;

	constructor(url: string) {
		super();

		this.url = url;
		this.ws = null;
		this.heartbeat = 0;
		this.decoder = new TextDecoder("utf-8");
		this.reconnecting = false;
		this.open = false;
	}

	public async connect(token: string): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				this.reconnecting = true;
				this.emit(GatewayManagerEvents.Connecting);

				if (this.ws) {
					this.ws.close();
					this.ws = null;
				}

				let resolved = false;
				this.once(GatewayManagerEvents.Connected, () => {
					if (resolved) {
						return;
					}
					resolved = true;
					resolve();
				});
				this.once(GatewayManagerEvents.Error, () => {
					if (resolved) {
						return;
					}
					resolved = true;
					reject(new Error("Failed to connect to Quebic Gateway, check internet connection."));
				});
				this.once(GatewayManagerEvents.Disconnected, (code: number) => {
					// We can extrapolate the error here and send it back to the callee
					// (in he case we didn't already connect)
					if (resolved) {
						return;
					}
					resolved = true;

					switch (code) {
						case QuebicWsErrorCodes.CloseOnUpgrade:
							reject(new Error("Failed to connect to Quebic Gateway, invalid authentication token provided."));
							break;
						default:
							reject(new Error("Failed to connect to Quebic Gateway, unknown error."));
							break;
					}
				});

				const callback = (result: boolean) => {
					const url = result ? `${this.url}?codec=lz4` : this.url;

					this.ws = new WebSocket(url, encodeURIComponent(`Bearer ${token}`));
					this.ws.binaryType = "arraybuffer";

					this.ws.onopen = () => this.onOpenEvent();
					this.ws.onclose = (ev: CloseEvent) => this.onCloseEvent(ev);
					this.ws.onerror = () => this.onErrorEvent();
					this.ws.onmessage = (ev: MessageEvent) => this.onMessageEvent(ev);
				};

				if (process.env.IS_WEBPACK === "webpack") {
					// Check whether or not LZ4 codec was loaded before submitting the request
					lz4Module?.then((m) => m.default)
						.then((module) => {
							lz4 = module;
							callback(!!lz4);
						}).catch((e) => {
							console.error("Failed to load LZ4 module: %s", e);
							console.error(e);
							callback(false);
						});
				} else {
					// LZ4 is always supported in nodejs mode
					callback(true);
				}
			} catch (e) {
				if (e instanceof Error) {
					this.reconnecting = false;
					reject(new Error(`Failed to connect to Quebic Gateway, ${e.message}`));
				} else {
					this.reconnecting = false;
					reject(new Error("Failed to connect to Quebic Gateway, unknown error occured."));
				}
			}
		});
	}

	public async send(message: string | Message): Promise<void> {
		const payload = message instanceof Message ? message.payload : message;

		return new Promise((resolve, reject) => {
			this.ws ? (this.ws.send(payload), resolve()) : reject(new Error("Not connected to Quebic Gateway"));
		});
	}

	public configureHeartbeat(interval: number): void {
		clearInterval(this.heartbeat);
		this.heartbeat = setInterval(async () => {
			if (this.connected) {
				await this.send(new Heartbeat());
			} else {
				clearInterval(this.heartbeat);
			}
		}, interval) as unknown as number;
	}

	public destroy() {
		this.ws?.close();
		this.removeAllListeners();
	}

	public get connected(): boolean {
		return !this.connecting && this.open;
	}

	public get connecting(): boolean {
		return this.reconnecting;
	}

	private onMessageEvent(ev: MessageEvent) {
		if (ev.data instanceof ArrayBuffer) {
			const result = lz4?.decompress(new Uint8Array(ev.data));
			const message = this.decoder.decode(result);

			this.emit(GatewayManagerEvents.Message, message);
		} else {
			this.emit(GatewayManagerEvents.Message, ev.data);
		}
	}

	private onOpenEvent() {
		this.open = true;
		this.reconnecting = false;
		this.emit(GatewayManagerEvents.Connected);
	}

	private onCloseEvent(ev: CloseEvent) {
		this.open = false;
		this.emit(GatewayManagerEvents.Disconnected, ev.code);
	}

	private onErrorEvent() {
		this.emit(GatewayManagerEvents.Error);
	}
}