import { Client } from "../client";
import { GatewayMessage } from "../internal/gateway";
import { BaseEvent } from "./baseEvent";

import {
	QuebicChannel,
	QuebicMessage,
	QuebicSpace,
	QuebicUserTyping,
	QuebicMessageReactionAdd,
	QuebicMessageReactionRemoveAll,
	QuebicMessageReactionRemove,
	QuebicSpaceMember,
	QuebicInvite,
	QuebicMessageAcknowledge
} from "../..";

/**
 * Represents what type of event this is and will help you determine what the data is as well.
 *
 * @export
 * @enum {number}
 */
export enum PushEventType {
	/**
	 * A channel was created in a space.
	 */
	ChannelCreated = 0x0,
	/**
	 * A channel was deleted.
	 */
	ChannelDeleted = 0x1,
	/**
	 * A channel was updated.
	 */
	ChannelUpdated = 0x2,
	/**
	 * A message was created in a channel.
	 */
	MessageCreated = 0x3,
	/**
	 * A message was deleted in a channel.
	 */
	MessageDeleted = 0x4,
	/**
	 * A message was updated in a channel.
	 */
	MessageUpdated = 0x5,
	/**
	 * A space was deleted.
	 */
	SpaceDeleted = 0x6,
	/**
	 * A space was updated.
	 */
	SpaceUpdated = 0x7,
	/**
	 * The current user session has joined a channel.
	 */
	ChannelJoin = 0x8,
	/**
	 * The current user session has left a channel.
	 */
	ChannelLeave = 0x9,
	/**
	 * The current user session has joined a space.
	 */
	SpaceJoin = 0xA,
	/**
	 * The current user session has left a space.
	 */
	SpaceLeave = 0xB,
	/**
	 * A reaction was added to a message.
	 */
	MessageReactionAdd = 0xC,
	/**
	 * A reaction was removed from a message.
	 */
	MessageReactionRemove = 0xD,
	/**
	 * All reaction(s) were removed from a message.
	 */
	MessageReactionRemoveAll = 0xE,
	/**
	 * A user has started typing in a channel.
	 */
	UserStartedTyping = 0xF,
	/**
	 * A space invite was created.
	 */
	InviteCreated = 0x10,
	/**
	 * A space invite was deleted.
	 */
	InviteDeleted = 0x11,
	/**
	 * A member of a space had an update.
	 */
	SpaceMemberUpdated = 0x12,
	/**
	 * A member of the space was deleted.
	 */
	SpaceMemberDeleted = 0x13,
	/**
	 * A message was 'read' in a channel.
	 */
	MessageAcknowledge = 0x14,
}

/**
 * Represents the data for a push event message.
 */
export interface PushEventData {
	t: PushEventType;
	o: unknown;
}

/**
 * *INTERNAL* use only, does not provide manual typings
 *
 * @export
 * @class PushEvent
 * @extends {BaseEvent}
 */
export class PushEventClass extends BaseEvent {
	constructor(client: Client, msg: GatewayMessage<PushEventData>) {
		super(client);

		this.type = msg.d.t;
		this.data = msg.d.o;
	}

	/**
	 * The type of event.
	 *
	 * @type {PushEventType}
	 * @memberof PushEvent
	 */
	public readonly type: PushEventType;

	/**
	 * The event data.
	 *
	 * @memberof PushEventClass
	 */
	public readonly data: unknown;
}

/**
 * Represents an event from the Quebic Gateway.
 * The data represented by this event is determined by `type`.
 */
export type PushEvent =
	{ readonly type: PushEventType.ChannelCreated, readonly data: QuebicChannel } & PushEventClass |
	{ readonly type: PushEventType.ChannelDeleted, readonly data: QuebicChannel } & PushEventClass |
	{ readonly type: PushEventType.ChannelUpdated, readonly data: QuebicChannel } & PushEventClass |

	{ readonly type: PushEventType.MessageCreated, readonly data: QuebicMessage } & PushEventClass |
	{ readonly type: PushEventType.MessageDeleted, readonly data: QuebicMessage } & PushEventClass |
	{ readonly type: PushEventType.MessageUpdated, readonly data: QuebicMessage } & PushEventClass |

	{ readonly type: PushEventType.SpaceDeleted, readonly data: QuebicSpace } & PushEventClass |
	{ readonly type: PushEventType.SpaceUpdated, readonly data: QuebicSpace } & PushEventClass |

	{ readonly type: PushEventType.ChannelJoin, readonly data: QuebicChannel } & PushEventClass |
	{ readonly type: PushEventType.ChannelLeave, readonly data: QuebicChannel } & PushEventClass |

	{ readonly type: PushEventType.SpaceJoin, readonly data: QuebicSpace } & PushEventClass |
	{ readonly type: PushEventType.SpaceLeave, readonly data: QuebicSpace } & PushEventClass |

	{ readonly type: PushEventType.MessageReactionAdd, readonly data: QuebicMessageReactionAdd } & PushEventClass |
	{ readonly type: PushEventType.MessageReactionRemove, readonly data: QuebicMessageReactionRemove } & PushEventClass |
	{ readonly type: PushEventType.MessageReactionRemoveAll, readonly data: QuebicMessageReactionRemoveAll } & PushEventClass |

	{ readonly type: PushEventType.UserStartedTyping, readonly data: QuebicUserTyping } & PushEventClass |

	{ readonly type: PushEventType.InviteCreated, readonly data: QuebicInvite } & PushEventClass |
	{ readonly type: PushEventType.InviteDeleted, readonly data: QuebicInvite } & PushEventClass |

	{ readonly type: PushEventType.SpaceMemberUpdated, readonly data: QuebicSpaceMember } & PushEventClass |
	{ readonly type: PushEventType.SpaceMemberDeleted, readonly data: QuebicSpaceMember } & PushEventClass |

	{ readonly type: PushEventType.MessageAcknowledge, readonly data: QuebicMessageAcknowledge } & PushEventClass;