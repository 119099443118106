import React from "react";
import "./LinkAccountApp.css";

import { Client } from '@quebicapp/quebicjs';

const CLIENT = new Client({
    api_endpoint: "https://api.quebic.com",
    cdn_endpoint: "https://cdn.quebic.com",
    mediaext_endpoint: "https://mediaext.quebic.com",
    gateway_endpoint: "wss://gateway.quebic.com",
    app_endpoint: "https://app.quebic.com",
});

function LinkAccountApp() {

    const [success, set_success] = React.useState(false);
    const [failure, set_failure] = React.useState(false);
    const [link_failure, set_link_failure] = React.useState(false);

    let params = new URLSearchParams(document.location.search);
    const un = params.get("username");
    let provider = params.get("provider");
    if (provider===null)
        provider="qreact-cognito-id-token";
    let token = params.get("token");
    if (token===null) token = "";
    
    const redirect_uri = params.get("redirect_uri");

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
	    document.getElementById("login-and-link").click();
        }
    }
    
    const loginAndLink = () => {
        const un = document.getElementById("username").value;
        const pw = document.getElementById("password").value;

        if (pw === "") {
	    alert("Password missing!");
	    return;
        }
        
        validateCredentials(un, pw)
	    .then(() => {
 		linkAccount();
	    })
	    .catch(err => {
                set_failure(true);
	    });
    }
    
    const validateCredentials = (un, pw) => {
        return new Promise((resolve, reject) => {
            CLIENT.user.login(un, pw)
	        .then(refr_token => {
	            CLIENT.user.refresh(refr_token.token)
		        .then(auth_token => {
		            CLIENT.connect(auth_token)
			        .then(() => {
			            resolve(true);
			        });
		        });
	        })
	        .catch(err => {
		    reject(false);
	        })
	    
        });
    }

    const linkAccount = () => {
        const provider = document.getElementById("provider").value;
        const token = document.getElementById("token").value;

        CLIENT.oauth.linkAccount(provider, "token", token)
	    .then(result => {
////	        console.log(`ACCOUNT LINKED`);
 	        CLIENT.destroy();
                set_success(true);
	    })
	    .catch(ex => {
	        console.log(`ERROR linking account`);
	        console.log(ex);
                set_link_failure(true);
	    })
    }

    const qSpaces = () => {
	CLIENT.space.list()
	    .then (spaces => {
		spaces.forEach(space => {
		    console.log(space);
		});
            });
    }
    
    const reload = () => {
        window.location.reload();
    }

    const redirect = () => {
        CLIENT.destroy();
        window.location = redirect_uri;
    }

    window.qLogin = validateCredentials;
    window.qLogout = () => { CLIENT.destroy()};
    window.qSpaces = qSpaces;
    
    if (redirect_uri===null) {
        return (
            <div className="app-mount">
                <div className="authentication">
	            <div className="logo">
	                <img src="/logo.svg" alt="Quebic Logo"/>
	            </div>
	            <div id="uri-failure" className="route">
	                <div className="login">
	                    <div className="container">
	                        <div className="title mt-12 fw-700">Missing Redirect</div>
	                        <div className="mb-12" noValidate="">
		                    <div className="mb-20">
		                        <h6 className="header fw-600 mb-4">The redirect uri is mssing</h6>
		                    </div>
		                    <div className="mt-8">
		                        <button onClick={reload} className="large grow fw-600" type="submit">Try Again</button>
		                    </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
        )
    }
    
    if (success) {
        return (
            <div className="app-mount">
                <div className="authentication">
	            <div className="logo">
	                <img src="/logo.svg" alt="Quebic Logo"/>
	            </div>
                    <div id="link-success" className="route">
	                <div className="login">
	                    <div className="container">
	                        <div className="title mt-12 fw-700">Successfully Linked Account</div>
	                        <div className="mb-12" noValidate="">
		                    <div className="mb-20">
		                        <h6 className="header fw-600 mb-4">You have successfully linked your Quebic accounts </h6>
		                    </div>
		                    <div className="mb-8">
		                        <h6 className="header text-upper fw-600 mb-4">Username</h6>
		                        <div id="link-username">{un} </div>
		                    </div>
		                    <div className="mb-8">
		                        <h6 className="header text-upper fw-600 mb-4">Provider</h6>
		                        <div id="link-provider" >{provider} </div>
		                    </div>
		                    <div className="mt-8">
		                        <button id="redirect-uri" value={redirect_uri} onClick={redirect} className="large grow fw-600" type="submit">Redirect</button>
		                    </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
        )
    }
    
    if (failure) {
        return (
            <div className="app-mount">
                <div className="authentication">
	            <div className="logo">
	                <img src="/logo.svg" alt="Quebic Logo"/>
	            </div>
	            <div id="link-failure" className="route">
	                <div className="login">
	                    <div className="container">
	                        <div className="title mt-12 fw-700">Invalid Credentials</div>
	                        <div className="mb-12" noValidate="">
		                    <div className="mb-20">
		                        <h6 className="header fw-600 mb-4">There was a problem with your credentials</h6>
		                    </div>
		                    <div className="mt-8">
		                        <button onClick={reload} className="large grow fw-600" type="submit">Try Again</button>
		                    </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
        )
    }

    if (link_failure) {
        return (
            <div className="app-mount">
                <div className="authentication">
	            <div className="logo">
	                <img src="/logo.svg" alt="Quebic Logo"/>
	            </div>
	            <div id="link-failure" className="route">
	                <div className="login">
	                    <div className="container">
	                        <div className="title mt-12 fw-700">Invalid Provider/Token</div>
	                        <div className="mb-12" noValidate="">
		                    <div className="mb-20">
		                        <h6 className="header fw-600 mb-4">There was a problem linking your account</h6>
		                    </div>
		                    <div className="mt-8">
		                        <button onClick={reload} className="large grow fw-600" type="submit">Try Again</button>
		                    </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
        )
    }

    // Otherwise present login form
    return (
        <div className="app-mount">
            <div className="authentication">
	        <div className="logo">
	            <img src="/logo.svg" alt="Quebic Logo"/>
	        </div>
	        <div id="login" className="route">
	            <div className="login" >
	                <div className="container">
	                    <div className="title mt-12 fw-700">Login</div>
	                    <div className="mb-12" noValidate="">
		                <div className="mb-20">
		                    <h6 className="header text-upper fw-600 mb-4">Email</h6>
		                    <input id="username" name="username" spellCheck={false} value={un} readOnly autoComplete="username" required="" type="text" className="no-select"/>
		                </div>
		                <div className="mb-8">
		                    <h6 className="header text-upper fw-600 mb-4">Password</h6>
		                    <input onKeyDown={handleKeyDown} id="password" name="password" spellCheck={false} autoComplete="current-password" required="" type="password"/>
		                </div>
		                <div className="mb-8">
		                    <h6 className="header text-upper fw-600 mb-4">Provider</h6>
		                    <input id="provider" spellCheck={false} value={provider} readOnly/>
		                </div>
		                <div className="mb-8">
		                    <h6 className="header text-upper fw-600 mb-4">Token</h6>
		                    <input id="token" spellCheck={false} value={token} readOnly/>
		                </div>
		                <div className="mt-8">
		                    <button id="login-and-link" onClick={loginAndLink} className="large grow fw-600" type="submit">Login and Link Account</button>
		                </div>
	                    </div>
	                </div>
	            </div>
	        </div>

            </div>
        </div>

    )

}

export default LinkAccountApp;
