import { QuebicRole } from "./role";
import { QuebicUser, QuebicUserPresence } from "./user";

/**
 * The required information to create a new space.
 *
 * @export
 * @interface QuebicSpaceCreate
 */
export interface QuebicSpaceCreate {
	/**
	 * The displayed name for the new space.
	 *
	 * @type {string}
	 * @memberof QuebicSpaceCreate
	 */
	display_name: string;
	/**
	 * A description for the new space.
	 *
	 * @type {string}
	 * @memberof QuebicSpaceCreate
	 */
	description?: string;
}

/**
 * Various flags that can be assigned to a space.
 */
export enum QuebicSpaceFlags {
	Verified = 0,
	Large = 1,
}

/**
 * Represents a Quebic space.
 *
 * @export
 * @interface QuebicSpace
 */
export interface QuebicSpace {
	/**
	 * The space id. This is a snowflake.
	 *
	 * @type {string}
	 * @memberof QuebicSpace
	 */
	id: string;
	/**
	 * The owner id. This is a snowflake.
	 *
	 * @type {string}
	 * @memberof QuebicSpace
	 */
	owner_id?: string;
	/**
	 * The display name for this space.
	 *
	 * @type {string}
	 * @memberof QuebicSpace
	 */
	display_name?: string;
	/**
	 * A description of this space.
	 *
	 * @type {string}
	 * @memberof QuebicSpace
	 */
	description?: string;
	/**
	 * The date when this space was created.
	 *
	 * @type {Date}
	 * @memberof QuebicSpace
	 */
	created_at?: Date;
	/**
	 * The space icon image id. This is a snowflake.
	 *
	 * @type {string}
	 * @memberof QuebicSpace
	 */
	icon?: string;
	/**
	 * The approximate number of members in the space.
	 *
	 * @type {number}
	 * @memberof QuebicSpace
	 */
	approximate_members?: number;
	/**
	 * The approximate number of members currently online in the space.
	 *
	 * @type {number}
	 * @memberof QuebicSpace
	 */
	approximate_members_online?: number;
	/**
	 * A collection of user access roles that belong to this space.
	 *
	 * @type {QuebicRole[]}
	 * @memberof QuebicSpace
	 */
	roles?: QuebicRole[];
	/**
	 * Set `true` when the space is unavailable. No functionality will be available during the outage.
	 *
	 * @type {boolean}
	 * @memberof QuebicSpace
	 */
	unavailable?: boolean;
	/**
	 * Flags that pertain to this space.
	 */
	flags?: string;
}

/**
 * Represents a space member with optional presence information.
 *
 * @export
 * @interface QuebicSpaceMember
 */
export interface QuebicSpaceMember {
	/**
	 * Part of a users information.
	 *
	 * @type {QuebicUser}
	 * @memberof QuebicSpaceMember
	 */
	user: QuebicUser,
	/**
	 * The id of the space for the membership.
	 *
	 * @type {string}
	 * @memberof QuebicSpaceMember
	 */
	space_id: string,
	/**
	 * A list of role id's assigned to the member.
	 *
	 * @type {string[]}
	 * @memberof QuebicSpaceMember
	 */
	roles?: string[],
	/**
	 * The users precense information if available.
	 *
	 * @type {QuebicUserPresence}
	 * @memberof QuebicSpaceMember
	 */
	presence?: QuebicUserPresence,
}

/**
 * Represents the updatable fields of a space member.
 *
 * @export
 * @interface QuebicSpaceMemberUpdate
 */
export interface QuebicSpaceMemberUpdate {
	/**
	 * The roles that should be assigned to this member.
	 */
	roles?: string[];
}