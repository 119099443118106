import axios from "axios";
import { QuebicSpaceMemberUpdate } from "../../types/space";
import { Controller } from "./controller";

export class Member extends Controller {
	protected get base(): string {
		return `${super.base}/space`;
	}

	/**
	 * Updates one or more attributes of a member of the given space.
	 *
	 * @param {string} space_id
	 * @param {string} user_id
	 * @param {QuebicSpaceMemberUpdate} updates
	 * @memberof Member
	 */
	public async update(space_id: string, user_id: string, updates: QuebicSpaceMemberUpdate) {
		await this.fetch(axios.patch(`${this.base}/${space_id}/members/${user_id}`, updates, this.configAuth));
	}
}