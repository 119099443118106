import { GatewayManager, GatewayManagerEvents, GatewayMessage } from "./internal/gateway";
import { EventEmitter } from "events";
import { TypedEventEmitter } from "../types/typedEmitter";
import { QuebicOpcodes } from "../base";
import { User } from "./controllers/user";
import { Controller } from "./controllers/controller";
import { Space } from "./controllers/space";
import { Channel } from "./controllers/channel";
import { Message } from "./controllers/message";
import { Pins } from "./controllers/pins";
import { Invite } from "./controllers/invite";
import { Media } from "./controllers/media";
import { Gif } from "./controllers/gif";
import { Cdn } from "./controllers/cdn";
import { Dm } from "./controllers/dm";
import { Sso } from "./controllers/sso";
import { Role } from "./controllers/role";
import { OAuth } from "./controllers/oauth";
import { Member } from "./controllers/member";
import { RoleOverride } from "./controllers/roleOverride";
import { QuebicToken } from "../types/user";
import { QuebicError } from "../types/error";
import { Ready, ReadySupplemental } from "../types/ready";
import { Json2 } from "./internal/json";
import { Heartbeat } from "./message/heartbeat";
import { PushEvent, PushEventClass, PushEventData } from "./events/pushEvent";
import { MemberListEvent, MemberListEventData } from "./events/memberListEvent";
import { SearchMemberListData, SearchMemberListEvent } from "./events/searchMemberListEvent";

/**
 * Events that are available to listen to on the Quebic Client.
 *
 * @export
 * @enum {number}
 */
export enum ClientEvents {
	/**
	 *	You're ready to use the Quebic client. Whoop whoop!
	 *  We also have some cool information for you like who you are, some spaces, and stuff.
	 */
	Ready = "ready",
	/**
	 * You're already connected to the Quebic client, but we have some important information that was missed.
	 */
	ReadySupplemental = "ready_supplemental",
	/**
	 *	Connected to the Quebic Gateway but we're waiting on a welcome message.
	 */
	Connected = "connected",
	/**
	 *	Connecting to the Quebic Gateway as we speak!
	 */
	Connecting = "connecting",
	/**
	 *	Disconnected from the Quebic Gateway.
	 */
	Disconnected = "disconnected",
	/**
	 *	Something important happened you should know about (Here it is!)
	 *	This could be a change in a space, channel, a new message, or anything of the sort.
	 */
	PushEvent = "push_event",
	/**
	 * An update to the member list has been pushed to the client.
	 * It contains a list of different commands that modify your list and should be processed.
	 */
	MemberListUpdate = "member_list_update",
	/**
	 * Search results for a call to space.searchMemberList() are ready.
	 */
	SearchMemberList = "search_member_list",
	/**
	 * The Quebic Gateway has indicated that you must reconnect to continue.
	 * (Your client won't work properly without doing so)
	 */
	ReconnectRequired = "reconnect_required",
	/**
	 *	An error occured. :(
	 */
	Error = "error",
}

/**
 * The client configuration values. Contains information related to how the client should function and connect with the Quebic
 * API and Gateway services.
 *
 * @export
 * @interface ClientConfiguration
 */
export interface ClientConfiguration {
	/**
	 * The Quebic API endpoint to use for all client rest calls.
	 *
	 * @type {string}
	 * @memberof ClientConfiguration
	 */
	api_endpoint: string;
	/**
	 * The Quebic CDN endpoint to use for accessing assets in our storage.
	 *
	 * @type {string}
	 * @memberof ClientConfiguration
	 */
	cdn_endpoint: string;
	/**
	 * The Quebic Gateway endpoint to use for realtime events.
	 *
	 * @type {string}
	 * @memberof ClientConfiguration
	 */
	gateway_endpoint: string;
	/**
	 * The Quebic MediaExt endpoint to use for proxying external media.
	 *
	 * @type {string}
	 * @memberof ClientConfiguration
	 */
	mediaext_endpoint: string;
	/**
	 * The Quebic App endpoint to use for generating invite urls.
	 *
	 * @type {string}
	 * @memberof ClientConfiguration
	 */
	app_endpoint: string;
}

/**
 * An interface that describes the available client events for the emitter.
 */
interface ClientEvent {
	[ClientEvents.Ready]: (event: Ready) => void,
	[ClientEvents.ReadySupplemental]: (event: ReadySupplemental) => void,
	[ClientEvents.Connected]: () => void,
	[ClientEvents.Connecting]: () => void,
	[ClientEvents.Disconnected]: () => void,
	[ClientEvents.PushEvent]: (event: PushEvent) => void,
	[ClientEvents.MemberListUpdate]: (event: MemberListEvent) => void,
	[ClientEvents.SearchMemberList]: (event: SearchMemberListEvent) => void,
	[ClientEvents.ReconnectRequired]: () => void,
	[ClientEvents.Error]: (error: Error) => void,
}

/**
 * The Quebic Client, this will help you manage all Quebic services.
 * 
 * You will need `quebicjs-voice` in order to connect/manage voice and video calls.
 *
 * @export
 * @class Client
 * @extends {EventEmitter}
 */
export class Client extends (EventEmitter as new () => TypedEventEmitter<ClientEvent>) {
	private gateway: GatewayManager | null;
	private token: string | null;

	private readonly endpoints: string[];

	private opcodeCallbacks: ((msg: GatewayMessage<any>) => void)[] = [
		this.opcodeHello,
		this.opcodePushEvent,
		this.opcodeHeartbeat,
		this.opcodeReconnect,
		this.opcodeMemberList,
		this.opcodeSearchMemberList,
		this.opcodeReady,
		this.opcodeReadySupplemental,
		this.opcodeCount
	];

	private controllers: Controller[] = [
		new User(this),
		new Space(this),
		new Channel(this),
		new Message(this),
		new Pins(this),
		new Invite(this),
		new Gif(this),
		new Media(this),
		new Cdn(this),
		new Dm(this),
		new OAuth(this),
		new Sso(this),
		new Role(this),
		new Member(this),
		new RoleOverride(this)
	];

	/**
	 * Creates an instance of the Quebic Client.
	 * @memberof Client
	 */
	constructor(config: ClientConfiguration) {
		super();

		this.endpoints = [config.api_endpoint, config.gateway_endpoint, config.mediaext_endpoint, config.cdn_endpoint, config.app_endpoint];

		this.token = null;
		this.gateway = null;
	}

	/**
	 * Methods for working with the User object in Quebic.
	 * (This includes authentication)
	 *
	 * @readonly
	 * @type {User}
	 * @memberof Client
	 */
	public get user(): User {
		return this.controllers[0] as User;
	}

	/**
	 * Methods for working with the Space object in Quebic.
	 *
	 * @readonly
	 * @type {Space}
	 * @memberof Client
	 */
	public get space(): Space {
		return this.controllers[1] as Space;
	}

	/**
	 * Methods for working with the Channel object in Quebic.
	 *
	 * @readonly
	 * @type {Channel}
	 * @memberof Client
	 */
	public get channel(): Channel {
		return this.controllers[2] as Channel;
	}

	/**
	 * Methods for working with the Message object in Quebic.
	 *
	 * @readonly
	 * @type {Message}
	 * @memberof Client
	 */
	public get message(): Message {
		return this.controllers[3] as Message;
	}

	/**
	 * Methods for working with the Pins object in Quebic.
	 *
	 * @readonly
	 * @type {Pins}
	 * @memberof Client
	 */
	public get pins(): Pins {
		return this.controllers[4] as Pins;
	}

	/**
	 * Methods for working with the Invite object in Quebic.
	 *
	 * @readonly
	 * @type {Invite}
	 * @memberof Client
	 */
	public get invite(): Invite {
		return this.controllers[5] as Invite;
	}

	/**
	 * Methods for working with gif's in Quebic.
	 *
	 * @readonly
	 * @type {Gif}
	 * @memberof Client
	 */
	public get gif(): Gif {
		return this.controllers[6] as Gif;
	}

	/**
	 * Methods for working with media in Quebic.
	 *
	 * @readonly
	 * @type {Media}
	 * @memberof Client
	 */
	public get media(): Media {
		return this.controllers[7] as Media;
	}

	/**
	 * Methods for working with the cdn in Quebic.
	 *
	 * @readonly
	 * @type {Cdn}
	 * @memberof Client
	 */
	public get cdn(): Cdn {
		return this.controllers[8] as Cdn;
	}

	/**
	 * Methods for working with private channels in Quebic.
	 *
	 * @readonly
	 * @type {Dm}
	 * @memberof Client
	 */
	public get dm(): Dm {
		return this.controllers[9] as Dm;
	}

	/**
	 * Methods for working with OAuth2 Providers in Quebic.
	 *
	 * @readonly
	 * @type {OAuth}
	 * @memberof Client
	 */
	public get oauth(): OAuth {
		return this.controllers[10] as OAuth;
	}

	/**
	 * Methods for working with SSO authentication in Quebic.
	 *
	 * @readonly
	 * @type {Sso}
	 * @memberof Client
	 */
	public get sso(): Sso {
		return this.controllers[11] as Sso;
	}

	/**
	 * Methods for working with Roles in Quebic.
	 *
	 * @readonly
	 * @type {Role}
	 * @memberof Client
	 */
	public get role(): Role {
		return this.controllers[12] as Role;
	}

	/**
	 * Methods for working with Members in Quebic.
	 *
	 * @readonly
	 * @type {Member}
	 * @memberof Client
	 */
	public get member(): Member {
		return this.controllers[13] as Member;
	}

	/**
	 * Methods for working with Role Overrides in Quebic.
	 *
	 * @readonly
	 * @type {RoleOverride}
	 * @memberof Client
	 */
	public get roleOverride(): RoleOverride {
		return this.controllers[14] as RoleOverride;
	}

	/**
	 * The current user session token.
	 *
	 * @readonly
	 * @type {(string | null)}
	 * @memberof Client
	 */
	public get sessionToken(): string | null {
		return this.token;
	}

	/**
	 * Sets a user session and connects to the Quebic Gateway with the specified session token.
	 * If you are already connected to the gateway, the connection will be terminated and restarted.
	 *
	 * @param {QuebicToken} session
	 * @return {*}  {Promise<void>}
	 * @memberof Client
	 */
	public async connect(session: QuebicToken): Promise<void> {
		// Set the token globally and auth the controllers.
		this.setToken(session);

		if (this.gateway) {
			this.gateway.destroy();
			this.gateway = null;
		}
		this.gateway = new GatewayManager(this.endpoints[1]);

		this.gateway.on(GatewayManagerEvents.Connected, () => this.emit(ClientEvents.Connected));
		this.gateway.on(GatewayManagerEvents.Connecting, () => this.emit(ClientEvents.Connecting));
		this.gateway.on(GatewayManagerEvents.Disconnected, () => this.emit(ClientEvents.Disconnected));
		this.gateway.on(GatewayManagerEvents.Message, (...args: any[]) => this.onMessage(args[0]));

		await this.gateway.connect(session.token);
	}

	/**
	 * Sets a user session. This will not impact the connection to the Quebic Gateway. You probably
	 * want to call `client.connect(session)` before calling this method.
	 *
	 * @param {QuebicToken} session
	 * @return {*}  {void}
	 * @memberof Client
	 */
	public setToken(session: QuebicToken): void {
		// Prevent the user from providing a non-auth token
		if (session.token_type !== "auth") {
			throw new QuebicError(-1, "invalid_session_token_type");
		}

		this.token = session.token;
	}

	/**
	 * Gets the current user session that was set with `setToken` or `login`.
	 * @returns The token itself.
	 */
	public getToken(): string | null {
		return this.token;
	}

	/**
	 * Destroys the user session and disconnects from the Quebic Gateway.
	 *
	 * @return {*}  {void}
	 * @memberof Client
	 */
	public destroy(): void {
		this.gateway?.destroy();

		this.token = "";
		this.gateway = null;
	}

	private onMessage(message: string) {
		try {
			const msg = Json2.parse(message) as GatewayMessage<any>;

			if (this.supportedOpcode(msg.o)) {
				this.opcodeCallbacks[msg.o].call(this, msg);
			} else {
				this.emit(ClientEvents.Error, new Error(`Server sent an unknown opcode: ${msg.o}`));
			}
		} catch (e) {
			this.emit(ClientEvents.Error, e as Error);
		}
	}

	private supportedOpcode(opcode: number): boolean {
		return opcode >= QuebicOpcodes.Hello && opcode <= QuebicOpcodes.Count;
	}

	private opcodeHello(msg: GatewayMessage<number>): void {
		this.gateway?.configureHeartbeat(msg.d);
	}

	private async opcodePushEvent(msg: GatewayMessage<PushEventData>) {
		this.emit(ClientEvents.PushEvent, new PushEventClass(this, msg) as PushEvent);
	}

	private async opcodeHeartbeat() {
		await this.gateway?.send(new Heartbeat());
	}

	private async opcodeReconnect() {
		this.emit(ClientEvents.ReconnectRequired);
	}

	private async opcodeMemberList(msg: GatewayMessage<MemberListEventData>) {
		this.emit(ClientEvents.MemberListUpdate, new MemberListEvent(this, msg));
	}

	private async opcodeSearchMemberList(msg: GatewayMessage<SearchMemberListData>) {
		this.emit(ClientEvents.SearchMemberList, new SearchMemberListEvent(this, msg));
	}

	private async opcodeReady(msg: GatewayMessage<Ready>) {
		this.emit(ClientEvents.Ready, msg.d);
	}

	private async opcodeReadySupplemental(msg: GatewayMessage<ReadySupplemental>) {
		this.emit(ClientEvents.ReadySupplemental, msg.d);
	}

	private opcodeCount(msg: GatewayMessage<any>): void {
		this.emit(ClientEvents.Error, new Error(`Failed to parse message: ${msg.o}`));
	}
}