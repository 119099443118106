import { QuebicRoleOverride } from "./role";
import { QuebicUser } from "./user";

/**
 * The required information to create a new text channel.
 *
 * @export
 * @interface QuebicChannelCreate
 */
export interface QuebicChannelCreate {
	/**
	 * The name of this channel.
	 */
	display_name: string,
	/**
	 * An optional description for this channel.
	 */
	description?: string,
	/**
	 * Whether or not the channel is private (a cohort).
	 */
	is_private: boolean,
}

/**
 * The updatable attributes of a channel.
 *
 * @export
 * @interface QuebicChannelUpdate
 */
export interface QuebicChannelUpdate {
	/**
	 * Used to transfer ownership of a private channel.
	 */
	owner_id?: string;
	/**
	 * The name of this channel.
	 */
	display_name?: string;
	/**
	 * The description for this channel.
	 */
	description?: string;
	/**
	 * Used to change the visibility of a private channel.
	 */
	is_private?: boolean;
}

/**
 * The mode configuration of a channel.
 *
 * @export
 * @enum {number}
 */
export enum QuebicChannelType {
	/**
	 * A standard text channel that belongs to a Space.
	 */
	SpaceChannel = 0x0,
	/**
	 * A standard voice channel that belongs to a Space.
	 */
	SpaceVoice = 0x1,
	/**
	 * A group private message channel between 2 people.
	 */
	DirectMessage = 0x2,
	/**
	 * A group private message channel between 3-10 people.
	 */
	GroupDirectMessage = 0x3,
}

/**
 * Represents a text based communication channel.
 *
 * @export
 * @interface QuebicChannel
 */
export interface QuebicChannel {
	/**
	 * The channel id. This is a snowflake.
	 */
	id: string;
	/**
	 * If this channel is private (cohort) this is the id of the user who created it.
	 * This is a snowflake.
	 */
	owner_id?: string;
	/**
	 * If this channel belongs to a space, this is the spaces id.
	 * This is a snowflake.
	 */
	space_id?: string;
	/**
	 * The channel icon image id. This is a snowflake.
	 */
	icon?: string;
	/**
	 * The type of channel.
	 */
	type?: QuebicChannelType;
	/**
	 * If this channel is a direct message, this contains the other users in the group.
	 */
	recipients?: QuebicUser[];
	/**
	 * The name of this channel.
	 */
	display_name?: string;
	/**
	 * A description for this channel.
	 */
	description?: string;
	/**
	 * A list of role overrides that apply specifically to this channel.
	 */
	role_overrides?: QuebicRoleOverride[];
	/**
	 * The latest message id of a message in this channel, however, this message may not exist and is only used to 
	 */
	last_message_id?: string;
	/**
	 * Whether or not this channel is private, and invite-only.
	 */
	is_private?: boolean;
	/**
	 * Whether or not the messages in this channel are end-to-end encrypted.
	 */
	is_encrypted?: boolean;
}